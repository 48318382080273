@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);

@font-face {
    font-family: 'SLEIGothicTTF';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2104@1.0/SLEIGothicTTF.woff')
        format('woff');
    font-weight: normal;
    font-style: normal;
}
html {
    font-size: 10px !important;
    overflow-x: hidden;
}
@media (max-width: 1700px) {
    html {
        font-size: 8px !important;
    }
}
@media (max-width: 1055px) {
    html {
        font-size: 7px !important;
    }
}
@media (max-width: 927px) {
    html {
        font-size: 6.2px !important;
    }
}
@media (max-width: 820px) {
    html {
        font-size: 6px !important;
    }
}
body {
    margin: 0;
    overflow-x: hidden;
    font-family: 'NanumSquare', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ant-menu,
.ant-menu-item,
.ant-menu-item-icon,
.ant-menu-submenu-title {
    font-size: 2rem !important;
}
.ant-menu-horizontal {
    line-height: 3.4rem !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.ant-input-affix-wrapper {
    background-color: #222831 !important;
    border: none !important;
    margin-bottom: 1rem;
}
.ant-input-affix-wrapper > .ant-input {
    background-color: #222831 !important;
    font-size: 2.4rem;
    color: #eeeeee !important;
    border: none !important;
}
.anticon-close-circle > svg {
    width: 2rem;
    height: 2rem;
    color: #eeeeee !important;
}

.ant-message {
    font-size: 2rem !important;
    padding: 2rem !important;
    line-height: normal !important;
}
.ant-message .anticon {
    font-size: 2rem !important;
}
.ant-message-notice-content {
    padding: 2rem !important;
}
